import React from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



import Styles from './skillsSection.module.scss'
import front from '../../assets/skillsSVG/frontend.svg'
import uiux from '../../assets/skillsSVG/uiux.svg'
import back from '../../assets/skillsSVG/backend.svg'


const SkillsSection = () => {
  return (
    <>
    <section className={Styles.skillsContainer}>
      <div className={`${Styles.skillsWrapper} `}>
        <div className={Styles.descriptionContentLeft}>
          <h2>Frontend Development</h2>
          <p>HTML, CSS og Javascript kan sagtens klare jobbet alene. 
            Men eftersom et projekt vokser kan struktur, vedligeholdelse 
            og ydeevne have en meget vigtig betydning for udvikleren og 
            slutbrugerens oplevelse.  Hvorfor det er fornuftigt at bruge 
            frameworks der tager sig af “The Heavy Liften”. Jeg gøre 
            bl. a. brug af en CSS preprocessor Sass med BEM metoden, 
            React Framework herunder NextJS (server side rendering) 
            GatsbyJS (Static Site Generator) og meget mere.</p>
        </div>
        <div className={Styles.skillsImg}>
        <img src={front} alt="usman kiyani"/> 
        </div>    
      </div>
    </section>

    <section className={Styles.skillsContainer}>
      <div className={`${Styles.skillsWrapper} `}>
        <div className={Styles.descriptionContentRight}>
          <h2>Backend Development</h2>
          <p>Jeg har udviklet/brugt CMS systemer som Wordpress 
            til Headless CMS systemer som Contentful. Derudover 
            har jeg arbejdet og skrevet mine egne web-api ´er 
            (endpoints) i Node.js som REST-API eller GraphQL,
             der enten taler til en NoSQL eller SQL database.
              Min programmerings tilgang er udført med enten 
              functional og eller OOP, med fokus på optimale 
            datastruktur, algoritme og ikke mindst brugen af
             relevante design patterns. 
            </p>
        </div>
        <div className={Styles.skillsImg}>
        <img src={back} alt="usman kiyani"/> 
        </div> 
        
      </div>
    </section>

    <section className={Styles.skillsContainer}>
      <div className={`${Styles.skillsWrapper} `}>
        <div className={Styles.descriptionContentLeft}>
          <h2>UI-UX Design</h2>
          <p>Udsende og brugeroplevelse går hånd i hånd. Derfor er det vigtigt med et stærkt 
            og funktionel design der ikke kun er fryd for øjet, men også nemt at bruge. 
            Jeg laver alt fra persona building, wireframing, mock-up og tester ver enkelt stadie for at opnår det bedst mulig resultat. </p>
            
            <div className={Styles.featureWrapper}>
              <div className={Styles.checkLine}>
                <FontAwesomeIcon className={Styles.svgIcon} icon={faCheck} /> 
                <p>Design research </p>
              </div>
              <div className={Styles.checkLine}>
                <FontAwesomeIcon className={Styles.svgIcon} icon={faCheck} /> 
                <p>Design Implementation </p>
              </div>
              <div className={Styles.checkLine}>
                <FontAwesomeIcon className={Styles.svgIcon} icon={faCheck} /> 
                <p>Usability testing</p>
              </div>
            </div>

        </div>
        <div className={Styles.skillsImg}>
        <img src={uiux} alt="usman kiyani"/> 
        </div>    
      </div>
    </section>
    </>
  )
}

export default SkillsSection

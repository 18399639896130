import React from 'react'

import Styles from './brands.module.scss';

const Brands = () => {
  return (
    <section className={Styles.brandsWrapper}>
      <div className={Styles.brandsContent}>
        <div className={Styles.brandsContentInner}>
        <div className={Styles.brandsItem}>
           <img src="//images.ctfassets.net/rbvlyhbn10d9/1M27GN1LToMCtZL0nGKPyx/1a1297232041dbd0195e185acf9a40a3/ex.png" alt="extrahånd.se"/>
          </div>
          <div className={Styles.brandsItem}>
          <img src="//images.ctfassets.net/rbvlyhbn10d9/2cByQeglkGJ4y8vr1gvyAL/262e91bc2f80e5949d3e53ffb5867610/pixel.jpg" alt="pixel.tv"/>

          </div>
          <div className={Styles.brandsItem}>
          <img src="//images.ctfassets.net/rbvlyhbn10d9/4s0FvUxc3rMtrZU8lp7G7w/ca1e55ddbc84817a4336d54ace892eb3/event.png" alt="eventcollective"/>

          </div>
          <div className={Styles.brandsItem}>
          <img src="//images.ctfassets.net/rbvlyhbn10d9/9LGm7vmvHhWqU40i9UC9h/227acc4ba5da6ffa44585210b981ea90/cfo.png" alt="careforothers"/>

          </div>
          <div className={Styles.brandsItem}>
          <img src="//images.ctfassets.net/rbvlyhbn10d9/49vrDJaAvncoxBb5okIM32/bb9ac397495c968bafd9e3e399577c2e/mls.png" alt="mylawstory"/>

          </div>

        </div>
      </div>
      
    </section>
  )
}

export default Brands

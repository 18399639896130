import React from 'react'

import Styles from './buttons.module.scss'

export const BtnYellowCurve = ({children}) => {
  return (
    <div className={`${Styles.btn}`}>
      <div className={`${Styles.btnYellowCurve} ${Styles.bold} ${Styles.btnLg}`}>
        {children}
      </div>
    </div>
  )
}

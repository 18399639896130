import React from "react"
import Head from '../components/utils/head'
import MainLayout from '../layout/mainLayout';
import LandingHero from "../components/landing_hero/landingHero";
import Brands from "../components/brands/brands";
import SkillsSection from "../components/skills/skillsSection";
import ProjectCardSlider from "../components/projectCardSlider/projectCardSlider";
import ContactMe from "../components/cta_contact/contactMe";


const Landing = () => {
  return (
    <MainLayout> 
      <Head title="Home"/>
     <LandingHero />
     <Brands />
      
      <ProjectCardSlider />

     <section className="section-title">
       <h2>
        Arbejde udført på forskellige felter
       </h2>
       <p>
         Udfordringer på forskellige områder har styrket mine kompetancer
       </p>
     </section>


     <SkillsSection />
     <ContactMe />
    </MainLayout>
  )
}

export default Landing;
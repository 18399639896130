import React from 'react'
import Styles from './Slider.module.scss';
import {Link, graphql, useStaticQuery } from 'gatsby';



const ProjectCardSlider = () => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulProject (limit: 3){
      edges {
        node {
          image {
            id
            file {
              url
            }
          }
          projectName
          projectType
          slug
        }
      }
    }
  }
  `)

  return (
    <div className={Styles.cardSliderContainer}>
      <div className={Styles.cardSliderContainerInner}>

        <div className={Styles.cardSliderHeaderWrapper}>
          <div className={Styles.cardSliderHeader}>
          <h2 className={Styles.projects}>Projects</h2>

            <Link to="/projects">
            <p className={Styles.showAll}>Show all</p>
            </Link>

          </div>
        </div>
      
        <div className={Styles.cardSliderTrack}>
                {data.allContentfulProject.edges.map(p => {
                  return (
                    <div className={Styles.cardComponent} key={p.node.image.id}>
                    <Link to={`/projects/${p.node.slug}`} >
                      <div className={Styles.cardComponentInner} >
                        <img src={p.node.image.file.url} alt={p.node.image.file.fileName}/>

                        <div className={Styles.cardDes}>
                          <div className={Styles.cardDesText}>
                            <h2>{p.node.projectName}</h2>
                            <p>{p.node.projectType}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                    </div>
                  )
                })}
                <div className="sliderSpacer">&emsp;&emsp;</div>
        </div>
      </div>
    </div>
  )
}

export default ProjectCardSlider

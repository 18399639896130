import React from 'react'
import {Link} from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

import Styles from './landingHero.module.scss';
import { BtnYellowCurve } from '../ui/buttons/buttons';
import heroSvg from '../../assets/usmanSVG.svg';
import { motion } from "framer-motion"
import ScrollDown from '../ui/buttons/scrollDown';

const LandingHero = () => {
  return (
    <section className={Styles.sectionHero}>
      <div className={Styles.sectionHeroInner}>
        <div className={Styles.contentHeroWrapper}>
          <div className={Styles.contentHeroWrapperInner}>
          <div className={Styles.heroContent}>
          <p className={Styles.pretitle}>USMAN KIYANI</p>
          <h1 className={Styles.h1}>
            Full Stack <br />
            Webdeveloper
          </h1>
          <p className={Styles.subtitle}>& Multi Media Designer</p>
          <div className={Styles.btn}>
          <Link to="/projects">
            <BtnYellowCurve>
              Projects <FontAwesomeIcon icon={faLongArrowAltRight} />  
            </BtnYellowCurve>
          </Link>
          <ScrollDown />
          </div>
        </div>
          </div>
 
        </div>
       <img className={Styles.img} src={heroSvg} alt="usman kiyani"/> 
      </div>

    </section>
  )
}

export default LandingHero

